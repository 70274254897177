<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->

    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
      <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="officialSendTemplate('worker')" type="primary" icon="plus">社工推送公众号卡片</a-button>
      <a-button
        type="primary"
        @click="searchReset"
        icon="reload"
        style="margin-left: 8px"
        >刷新状态</a-button
      >
    </div>


    <!-- table区域-begin -->
    <div>
      <a-table
        ref="table"
        size="middle"
        :scroll="{x:1800}"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
         :pagination="ipagination"
        :loading="loading"
        class="j-table-force-nowrap"
        @change="handleTableChange">

          <template slot="status" slot-scope="text, record">
            <a-tag color="#8B7500" v-if="text == 2">
                执行中
            </a-tag>
            <a-tag color="#5ac725" v-else-if="text == 3">
              执行成功
            </a-tag>
            <a-tag color="#FF2D2D" v-else-if="text == 4">
              执行失败
            </a-tag>
          </template>

          
          <template slot="type" slot-scope="text, record">
            <a-tag color="#5ac725"  v-if="text == 'official'">
                公众号
            </a-tag>
            <a-tag  v-else>
                其他
            </a-tag>
          </template>
          
          

        <span slot="action" slot-scope="text, record">
          <a-popconfirm title="确定回传上报吗?" @confirm="() => pedigreeCallBackClick(record)">
              <a>回传上报</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a @click="refundOrder(record)" v-if="otherDataSource.code">退款</a>
        </span>
      </a-table>
    </div>

    <a-modal
      title="上传推送卡片用户excel文件"
      :width="800"
      :visible="modalVisible"
      :confirmLoading="confirmLoading"
      switchFullscreen
      @ok="submitForm"
      @cancel="modalVisible = false"
      cancelText="关闭">
      <a-form  :form="form" :label-col="{ span: 17 }" :wrapper-col="{ span: 7}">
            <a-row>
              <a-col :span="24">
                  <a-upload
                    v-decorator="['file', { rules: [{ required: true, message: '请上传推送卡片用户excel文件!' }] }]"
                    :file-list="fileList"
                    :before-upload="beforeUpload"
                    accept=".xlsx"
                    :remove="handleRemove"
                  >
                    <div>
                        <a-button type="primary"> <a-icon type="upload" />上传推送卡片用户excel文件</a-button>
                    </div>
                  </a-upload>
              </a-col>
            </a-row>
      </a-form>
    </a-modal>
  </a-card>
</template>
 
<script>

  import '@/assets/less/TableExpand.less'
  import { mixinDevice } from '@/utils/mixin'
  import { CustomerShopMixin } from '@/mixins/CustomerShopMixin'
  import { getAction, postAction,uploadAction } from '@/api/manage'
  import moment from 'moment';
  export default {
    name: 'List',
    mixins:[CustomerShopMixin, mixinDevice],
    components: {
    },
    data () {
      return {
        description: 'List管理页面',
        dateFormat:'YYYY-MM-DD',
        modalVisible: false,
        confirmLoading: false,
        form: this.$form.createForm(this, { name: 'coordinated' }),
        refundParam: {},
        record:{},
        fileList: [],
        today:"2024-11-11",
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        // 表头
        columns: [
          {
            title: '名称',
            dataIndex: 'name',
            align:"center",
            ellipsis: true,
            width:180,
          },
          {
            title: '执行人',
            dataIndex: 'userName',
            align:"center",
            ellipsis: true,
            width:140,
          },
          {
            title: '类型',
            dataIndex: 'type',
            scopedSlots: { customRender: 'type' },
            align:"center",
            width:120,
            ellipsis: true,
          },
          {
            title: '状态',
            dataIndex: 'status',
            scopedSlots: { customRender: 'status' },
            align:"center",
            width:120,
            ellipsis: true,
          },
          {
            title: '执行时间',
            dataIndex: 'createTime',
            align:"center",
            width:180,
            ellipsis: true,
          },
          {
            title: '备注',
            dataIndex: 'remark',
            align:"center",
            width:180,
            ellipsis: true,
          },
        ],
        url: {
          list: "/shop/web/electronTools/getToolsSendLogList",
        },

      }
    },
    created() {
    },
    computed: {
    
    },
    methods: {
       moment,
       addRotation(){
          this.$refs.modalForm.add();
       },
       modalFormOk(){

       },
       onDateChange: function (value, dateString) {
          this.queryParam.startTime=dateString[0];
          this.queryParam.endTime=dateString[1];
       },
       officialSendTemplate(){
         this.fileList = []
         this.modalVisible = true
       },
       submitForm () {
          this.form.validateFields((err, values) => {
            if (!err) {
                const formData = new FormData();
                this.fileList.forEach(file => {
                  formData.append("file", file)  
                });  
                this.toToOfficialSendTemplate(formData);
            }
          })
        },
       toToOfficialSendTemplate(formData){
          let that  = this;
          this.confirmLoading = true
          uploadAction('/shop/web/electronTools/officialSendTemplate', formData).then((res)=>{
            if(res.code == 200){
              that.$message.success("执行成功");
              that.modalVisible = false;
              that.loadData();
            }else{
              that.$message.warn(res.message);
            }
          }).finally(() => {
            that.confirmLoading = false;
          })
       },
       beforeUpload(file) {
          this.fileList = [];
          this.fileList = [...this.fileList, file];
          return false;
        },
        handleRemove(file) {
            const index = this.fileList.indexOf(file);
            const newFileList = this.fileList.slice();
            newFileList.splice(index, 1);
            this.fileList = newFileList;
        },
    }
  }
</script>
<style scoped>
  @import '~@assets/less/common.less';
</style>